export enum QueryTypes {
  PROFILE = 'profile',
  CATALOG = 'catalog',
  CATEGORY = 'category',
  FAVORITES = 'favorites',
  DBPROFILE = 'dbprofile',
  RESTAURANT_SUPPLIER = 'restaurant-supplier',
  RESTAURANT_USER_INVITATION_DETAILS = 'restaurant-user-invitation-details',
  ORDER = 'order',
  INVITATION = 'invitation',
  ORDERS = 'orders',
  PENDING_ORDERS = 'pending_orders',
  SUPPLIER = 'supplier',
  PRODUCTS = 'products',
  FAVORITE_PRODUCTS = 'favorite_products',
  PRODUCT_CATEGORIES = 'product-categories',
  PRODUCT_UNITS = 'product-units',
  SUPPLIER_CATEGORIES = 'supplier-categories',
  SUPPLIER_SCHEDULE = 'supplier-schedule',
  ORDERS_EXPORT_PDF = 'orders_export_pdf',
  USERS_BY_COMPANY_WITH_DOMAIN = 'users-by-company-with-domain',
  USERS_BY_COMPANY = 'users-by-company',
  SUPPLIER_USERS = 'supplier-users',
}

export interface BaseApiRequest<T> {
  data: T[];
  links: {
    current: string;
  };
  meta: {
    currentPage: number;
    itemsPerPage: number;
    sortBy: string[][];
    totalItems: number;
    totalPages: number;
  };
}
