/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/naming-convention */
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
  useQuery,
  type UseQueryResult,
  type UseQueryOptions,
} from 'react-query';

import { type Currency } from '@/constants/Currency';
import Endpoints from '@/constants/Endpoints';
import { QueryTypes } from '@/constants/QueryTypes';
import axiosInstance from '@/services/api';
import { getOnUserSupplierInvitationResponseParse } from '@/utils/invitation';
import { supplierResponseParse } from '@/utils/supplier';

interface SearchAutocompleteFormData {
  search: string;
}

interface RequestAccessResponse {
  success: boolean;
}

interface RequestAccessFormData {
  supplier_id: number;
  full_name: string;
  email: string;
  phone_number: string;
}

interface UserValidationFormData {
  phone_number: string;
}

interface UserValidationResponse {
  exists: boolean;
  message: string;
}

interface RegisterSupplierFormData {
  name: string;
  address: string;
  registration_channel: string;
  contact_phone?: string;
  contact_email?: string;
}

interface RegisterSupplierResponse {
  success: boolean;
}

export interface GetRestaurantSupplierFormData {
  limit: number;
  page: number;
  search?: string;
  restaurant_id?: number;
  supplier_id?: number;
  seller_id?: number;
}

interface PatchSupplierFormData {
  id?: number;
  name?: string;
  address?: string;
  contact_email?: string;
  contact_phone?: string;
  currency?: Currency;
  order_cutoff_times?: SupplierOrderCutoffTimes;
  supplier_categories?: number[];
  main_supplier_category_id?: number;
  picture_url?: string;
  has_official_catalog?: boolean;
  is_available_for_chat?: boolean;
  shows_in_public_listings?: boolean;
  minimum_order_amount?: number;
  advance_days?: number;
}

export class SuppliersService {
  private static readonly _searchAutocomplete = async (formData: SearchAutocompleteFormData): Promise<any> => {
    try {
      const response: any = (
        await axiosInstance.get(`${Endpoints.SUPPLIER_SEARCH_AUTOCOMPLETE}`, { params: { search: formData.search } })
      ).data;
      return response || null;
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  public static useSearchAutocomplete = (
    options?: UseMutationOptions<any, Error, SearchAutocompleteFormData>,
  ): UseMutationResult<any, Error, SearchAutocompleteFormData> => {
    return useMutation<any, Error, SearchAutocompleteFormData>(
      async searchAutocompleteFormData => await SuppliersService._searchAutocomplete(searchAutocompleteFormData),
      options,
    );
  };

  private static readonly _requestAccess = async (formData: RequestAccessFormData): Promise<RequestAccessResponse> => {
    try {
      const response: RequestAccessResponse = (
        await axiosInstance.post(`${Endpoints.SUPPLIER_REQUEST_ACCESS}`, formData)
      ).data;
      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  public static useRequestAccess = (
    options?: UseMutationOptions<RequestAccessResponse, Error, RequestAccessFormData>,
  ): UseMutationResult<RequestAccessResponse, Error, RequestAccessFormData> => {
    return useMutation<RequestAccessResponse, Error, RequestAccessFormData>(
      async requestAccessFormData => await SuppliersService._requestAccess(requestAccessFormData),
      options,
    );
  };

  private static readonly _userValidation = async (
    formData: UserValidationFormData,
  ): Promise<UserValidationResponse> => {
    try {
      const response: UserValidationResponse = (
        await axiosInstance.post(`${Endpoints.SUPPLIER_USER_VALIDATION}`, formData)
      ).data;
      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  public static useUserValidation = (
    options?: UseMutationOptions<UserValidationResponse, Error, UserValidationFormData>,
  ): UseMutationResult<UserValidationResponse, Error, UserValidationFormData> => {
    return useMutation<UserValidationResponse, Error, UserValidationFormData>(
      async userValidationFormData => await SuppliersService._userValidation(userValidationFormData),
      options,
    );
  };

  private static readonly _registerSupplier = async (
    formData: RegisterSupplierFormData,
  ): Promise<RegisterSupplierResponse> => {
    try {
      const response: RegisterSupplierResponse = (
        await axiosInstance.post(`${Endpoints.SUPPLIER_REGISTRATION}`, formData)
      ).data;
      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  public static useRegisterSupplier = (
    options?: UseMutationOptions<RegisterSupplierResponse, Error, RegisterSupplierFormData>,
  ): UseMutationResult<RegisterSupplierResponse, Error, RegisterSupplierFormData> => {
    return useMutation<RegisterSupplierResponse, Error, RegisterSupplierFormData>(
      async registerSupplierFormData => await SuppliersService._registerSupplier(registerSupplierFormData),
      options,
    );
  };

  private static readonly _getRestaurantSupplier = async (formData: GetRestaurantSupplierFormData): Promise<any> => {
    const { restaurant_id, seller_id, supplier_id } = formData;

    const params = {
      'filter.restaurant_id': restaurant_id,
      'filter.supplier_id': supplier_id,
      'filter.seller_id': seller_id,
    };

    if (!restaurant_id) {
      delete params['filter.restaurant_id'];
    }

    if (!supplier_id) {
      delete params['filter.supplier_id'];
    }

    if (!seller_id) {
      delete params['filter.seller_id'];
    }

    try {
      const response: any = (await axiosInstance.get(`${Endpoints.RESTAURANT_SUPPLIER}`, { params })).data;
      return response.data;
    } catch (error: any) {
      throw new Error(error.error_description);
    }
  };

  public static useGetRestaurantSupplier = (
    formData: GetRestaurantSupplierFormData,
    { ...opts }: UseQueryOptions<any, Error>,
  ): UseQueryResult<any, Error> => {
    return useQuery<any, Error>(
      QueryTypes.RESTAURANT_SUPPLIER,
      async () => await this._getRestaurantSupplier(formData),
      {
        refetchOnWindowFocus: false,
        cacheTime: 0,
        ...opts,
      },
    );
  };

  public static useMutationGetRestaurantSupplier = (
    options?: UseMutationOptions<any, Error, any>,
  ): UseMutationResult<any, Error, any> => {
    return useMutation<any, Error, any>(
      async (formData: GetRestaurantSupplierFormData) => await SuppliersService._getRestaurantSupplier(formData),
      options,
    );
  };

  private static readonly _getOneUserInvitations = async (
    formData: GetOneUserInvitationsFormData,
  ): Promise<UserInvitation> => {
    try {
      const response: BDUserInvitation = (await axiosInstance.post(`${Endpoints.SUPPLIER_USER_INVITATIONS}`, formData))
        .data;
      return getOnUserSupplierInvitationResponseParse(response);
    } catch (error) {
      throw new Error(`Unknown error: ${error}`);
    }
  };

  public static useGetOneUserInvitations = (
    formData: GetOneUserInvitationsFormData,
    { ...opts }: UseQueryOptions<UserInvitation, Error>,
  ): UseQueryResult<UserInvitation, Error> => {
    return useQuery<UserInvitation, Error>(
      QueryTypes.INVITATION,
      async () => await this._getOneUserInvitations(formData),
      {
        refetchOnWindowFocus: false,
        cacheTime: 0,
        ...opts,
      },
    );
  };

  public static useMutateGetOneUserInvitations = (
    options?: UseMutationOptions<UserInvitation, Error, GetOneUserInvitationsFormData>,
  ): UseMutationResult<UserInvitation, Error, GetOneUserInvitationsFormData> => {
    return useMutation<UserInvitation, Error, GetOneUserInvitationsFormData>(
      async formData => await SuppliersService._getOneUserInvitations(formData),
      options,
    );
  };

  private static readonly _getGetSupplier = async (supplierId: number): Promise<any> => {
    try {
      const response: any = (await axiosInstance.get(`${Endpoints.SUPPLIER_GET}${supplierId}`)).data;
      return supplierResponseParse(response);
    } catch (error) {
      throw new Error(`Unknown error: ${error}`);
    }
  };

  public static useGetSupplier = (
    supplierId: number,
    { ...opts }: UseQueryOptions<any, Error>,
  ): UseQueryResult<any, Error> => {
    return useQuery<any, Error>(QueryTypes.SUPPLIER, async () => await this._getGetSupplier(supplierId), {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      ...opts,
    });
  };

  private static readonly _patchSupplier = async (formData: PatchSupplierFormData): Promise<any> => {
    const { id } = formData;
    delete formData.id;
    try {
      const response: any = (await axiosInstance.patch(`${Endpoints.SUPPLIER_GET}${id}`, formData)).data;
      return supplierResponseParse(response);
    } catch (error) {
      throw new Error(`Unknown error: ${error}`);
    }
  };

  public static usePatchSupplier = (
    options?: UseMutationOptions<any, Error, PatchSupplierFormData>,
  ): UseMutationResult<any, Error, PatchSupplierFormData> => {
    return useMutation<any, Error, PatchSupplierFormData>(
      async formData => await SuppliersService._patchSupplier(formData),
      options,
    );
  };

  private static readonly _getGetSupplierCategories = async (): Promise<any> => {
    try {
      const response: any = (
        await axiosInstance.get(`${Endpoints.SUPPLIER_CATEGORIES}`, {
          params: {
            limit: 50,
            page: 1,
          },
        })
      ).data;
      return response.data;
    } catch (error) {
      throw new Error(`Unknown error: ${error}`);
    }
  };

  public static useGetSupplierCategories = ({ ...opts }: UseQueryOptions<any, Error>): UseQueryResult<any, Error> => {
    return useQuery<any, Error>(QueryTypes.SUPPLIER_CATEGORIES, async () => await this._getGetSupplierCategories(), {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      ...opts,
    });
  };

  private static readonly _postSupplierSchedule = async (params: any): Promise<any> => {
    try {
      const response: any = (await axiosInstance.post(`${Endpoints.SUPPLIER_SCHEDULE}`, params)).data;
      return response.data;
    } catch (error) {
      throw new Error(`Unknown error: ${error}`);
    }
  };

  public static useMutateSupplierSchedule = (options?: UseMutationOptions<any, Error, PatchSupplierFormData>) => {
    return useMutation<any, Error, any>(
      QueryTypes.SUPPLIER_SCHEDULE,
      async params => await this._postSupplierSchedule(params),
    );
  };

  private static readonly _getSupplierSchedule = async (supplierId: number): Promise<any> => {
    try {
      const response: any = (await axiosInstance.get(`${Endpoints.SUPPLIER_GET}${supplierId}/schedule`)).data;
      return response;
    } catch (error) {
      throw new Error(`Unknown error: ${error}`);
    }
  };

  public static useGetSupplierSchedule = (supplierId: number, opts: UseQueryOptions<any, Error>) => {
    return useQuery<any, Error>(QueryTypes.SUPPLIER_SCHEDULE, async () => await this._getSupplierSchedule(supplierId), {
      ...opts,
    });
  };

  private static readonly _uploadFile = async (file: File): Promise<any> => {
    const formData = new FormData();
    formData.append('file', file);
    try {
      const response: any = (
        await axiosInstance.post(`${Endpoints.UPLOAD_FILE}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          transformRequest: (data, headers) => {
            return formData;
          },
        })
      ).data;
      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  public static useUploadFile = (options?: UseMutationOptions<any, Error, any>): UseMutationResult<any, Error, any> => {
    return useMutation<any, Error, any>(async file => await SuppliersService._uploadFile(file), options);
  };

  private static readonly _deleteRestaurant = async (restaurantId: number, supplierId: number): Promise<any> => {
    try {
      const response: any = (
        await axiosInstance.delete(Endpoints.RESTAURANT_SUPPLIER, {
          data: {
            supplier_id: supplierId,
            restaurant_id: restaurantId,
          },
        })
      ).data;
      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  public static useDeleteRestaurant = (
    options?: UseMutationOptions<any, Error, { supplierId: number | undefined; restaurantId: number }>,
  ): UseMutationResult<any, Error, { supplierId: number | undefined; restaurantId: number }> => {
    return useMutation<any, Error, { supplierId: number | undefined; restaurantId: number }>(
      async ({ restaurantId, supplierId }) =>
        await SuppliersService._deleteRestaurant(restaurantId, supplierId as number),
      options,
    );
  };

  public static storeSupplierInLocalStorage = (supplier: any): void => {
    const localStorageSupplier = window.localStorage.supplier;
    if (!localStorageSupplier) {
      window.localStorage.supplier = supplier;
    }
  };

  public static useGetSupplierFromLocalStorage = (): any => {
    const localStorageSupplier = window.localStorage.supplier;
    if (localStorageSupplier) {
      return JSON.parse(localStorageSupplier);
    }
    return null;
  };
}
